export type Role = `${string}:${string}`;

export enum EPermissionSection {
  USERS = "admin-dashboard-users",
  COMPANIES = "companies",
  PUBLISHERS = "publishers",
  PUBLISHERS_USERS = "publishers-users",
  PAYOUTS = "payouts",
  PAYMENT_METHODS = "payment-methods",
  BLOCKED_PLAYERS = "blocked-players",
  FEES = "fees",
  AUDITS = "audits",
  ROLES = "admin-dashboard-roles",
  ADVANCED_SETTINGS = "advanced-settings",
  RESTORE_RECEIPTS = "restore-receipts",
  LOGIN_TO_DASHBOARD_AS_SUPER_ADMIN = "login-to-dashboard-as-super-admin",
  LOGIN_TO_DASHBOARD_AS_SPECTATOR = "login-to-dashboard-as-spectator",
  DISPUTE_DATA_CENTER = "dispute-data-center",
  VAT_REPORTS = "vat-reports",
}

export enum EPermissionAction {
  CREATE = "create",
  GET = "get",
  EDIT = "edit",
  DELETE = "delete",
}
