import { Box, Fade, Stack } from "@mui/material";
import React, { useEffect, useState, memo, useMemo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Line,
  ZoomableGroup,
} from "react-simple-maps";
import { feature } from "topojson-client";
import { debounce } from "lodash";
import { Typography } from "@mui/material";
import MainCard from "components/MainCard";

const mapConfig = {
  projection: "geoMercator",
  projectionConfig: {
    scale: 150,
    center: [20, 30] as [number, number],
    rotate: [0, 0, 0] as [number, number, number],
  },
};
type TooltipData = {
  name: string;
  coordinates: { x: number; y: number };
} | null;

const COUNTRY_COORDINATES: { [key: string]: [number, number] } = {
  Afghanistan: [65, 33],
  Albania: [20, 41],
  Algeria: [3, 28],
  Angola: [17, -12],
  Argentina: [-64, -34],
  Armenia: [45, 40],
  Australia: [133, -27],
  Austria: [14, 47],
  Azerbaijan: [47, 40],
  Bahamas: [-77, 24],
  Bangladesh: [90, 24],
  Belarus: [28, 53],
  Belgium: [4, 50],
  Belize: [-88, 17],
  Benin: [2, 9],
  Bhutan: [90, 27],
  Bolivia: [-65, -17],
  Bosnia: [18, 44],
  Botswana: [24, -22],
  Brazil: [-55, -10],
  Brunei: [114, 4],
  Bulgaria: [25, 43],
  Burkina: [-2, 13],
  Burundi: [30, -3],
  Cambodia: [105, 13],
  Cameroon: [12, 6],
  Canada: [-95, 60],
  Chad: [19, 15],
  Chile: [-71, -30],
  China: [105, 35],
  Colombia: [-72, 4],
  Congo: [15, -1],
  "Costa Rica": [-84, 10],
  Croatia: [15, 45],
  Cuba: [-77, 22],
  Cyprus: [33, 35],
  "Czech Republic": [15, 49],
  Denmark: [10, 56],
  Djibouti: [43, 11],
  "Dominican Republic": [-70, 19],
  Ecuador: [-78, -2],
  Egypt: [30, 27],
  "El Salvador": [-89, 14],
  "Equatorial Guinea": [10, 2],
  Eritrea: [39, 15],
  Estonia: [25, 59],
  Ethiopia: [40, 8],
  Fiji: [178, -18],
  Finland: [26, 64],
  France: [2, 46],
  Gabon: [11, -1],
  Gambia: [-15, 13],
  Georgia: [43, 42],
  Germany: [10, 51],
  Ghana: [-1, 8],
  Greece: [22, 39],
  Guatemala: [-90, 15],
  Guinea: [-10, 11],
  "Guinea-Bissau": [-15, 12],
  Guyana: [-59, 5],
  Haiti: [-72, 19],
  Honduras: [-86, 15],
  Hungary: [20, 47],
  Iceland: [-19, 65],
  India: [77, 20],
  Indonesia: [120, -5],
  Iran: [53, 32],
  Iraq: [44, 33],
  Ireland: [-8, 53],
  Israel: [35, 31],
  Italy: [12, 42],
  "Ivory Coast": [-5, 8],
  Jamaica: [-77, 18],
  Japan: [138, 36],
  Jordan: [36, 31],
  Kazakhstan: [67, 48],
  Kenya: [38, 0],
  Kuwait: [47, 29],
  Kyrgyzstan: [75, 41],
  Laos: [102, 18],
  Latvia: [25, 57],
  Lebanon: [35, 33],
  Lesotho: [28, -29],
  Liberia: [-9, 6],
  Libya: [17, 27],
  Lithuania: [24, 55],
  Luxembourg: [6, 49],
  Macedonia: [22, 41],
  Madagascar: [47, -20],
  Malawi: [34, -13],
  Malaysia: [102, 4],
  Mali: [-4, 17],
  Mauritania: [-12, 20],
  Mexico: [-102, 23],
  Moldova: [29, 47],
  Mongolia: [105, 46],
  Montenegro: [19, 42],
  Morocco: [-5, 32],
  Mozambique: [35, -18],
  Myanmar: [96, 22],
  Namibia: [17, -22],
  Nepal: [84, 28],
  Netherlands: [5, 52],
  "New Zealand": [174, -41],
  Nicaragua: [-85, 13],
  Niger: [8, 16],
  Nigeria: [8, 10],
  "North Korea": [127, 40],
  Norway: [8, 62],
  Oman: [56, 21],
  Pakistan: [69, 30],
  Panama: [-80, 9],
  "Papua New Guinea": [143, -6],
  Paraguay: [-58, -23],
  Peru: [-76, -10],
  Philippines: [122, 13],
  Poland: [20, 52],
  Portugal: [-8, 39],
  Qatar: [51, 25],
  Romania: [25, 46],
  Russia: [100, 60],
  Rwanda: [30, -2],
  "Saudi Arabia": [45, 25],
  Senegal: [-14, 14],
  Serbia: [21, 44],
  "Sierra Leone": [-11, 8],
  Slovakia: [19, 48],
  Slovenia: [15, 46],
  Somalia: [46, 6],
  "South Africa": [22, -30],
  "South Korea": [128, 36],
  "South Sudan": [31, 7],
  Spain: [-4, 40],
  "Sri Lanka": [81, 7],
  Sudan: [30, 15],
  Suriname: [-56, 4],
  Swaziland: [31, -26],
  Sweden: [15, 62],
  Switzerland: [8, 47],
  Syria: [39, 35],
  Taiwan: [121, 24],
  Tajikistan: [71, 39],
  Tanzania: [35, -6],
  Thailand: [101, 15],
  Togo: [1, 8],
  Tunisia: [9, 34],
  Turkey: [35, 39],
  Turkmenistan: [59, 40],
  Uganda: [32, 1],
  Ukraine: [32, 49],
  "United Arab Emirates": [54, 24],
  "United Kingdom": [-2, 54],
  "United States of America": [-97, 38],
  Uruguay: [-56, -33],
  Uzbekistan: [64, 41],
  Venezuela: [-66, 8],
  Vietnam: [106, 16],
  Yemen: [48, 15],
  Zambia: [28, -13],
  Zimbabwe: [29, -19],
  Singapore: [103.8, 1.3],
};

type Connection = {
  from: keyof typeof COUNTRY_COORDINATES;
  to: keyof typeof COUNTRY_COORDINATES;
  color?: string;
  width?: number;
};

enum ECountryState {
  ENTITY = "ENTITY",
  FULLY_REGISTERED = "FULLY_REGISTERED",
  REGISTRATION_PROCESSING = "REGISTRATION_PROCESSING",
  FUTURE_REGISTRATION = "FUTURE_REGISTRATION",
}

const COUNTRY_STATES: { [key: string]: ECountryState } = {
  // Europe (ALL FULLY_REGISTERED)
  Albania: ECountryState.FULLY_REGISTERED,
  Andorra: ECountryState.FULLY_REGISTERED,
  Austria: ECountryState.FULLY_REGISTERED,
  Belarus: ECountryState.FULLY_REGISTERED,
  Belgium: ECountryState.FULLY_REGISTERED,
  BosniaAndHerzegovina: ECountryState.FULLY_REGISTERED,
  Bulgaria: ECountryState.FULLY_REGISTERED,
  Croatia: ECountryState.FULLY_REGISTERED,
  Cyprus: ECountryState.FULLY_REGISTERED,
  CzechRepublic: ECountryState.FULLY_REGISTERED,
  Denmark: ECountryState.FULLY_REGISTERED,
  Estonia: ECountryState.FULLY_REGISTERED,
  Finland: ECountryState.FULLY_REGISTERED,
  France: ECountryState.FULLY_REGISTERED,
  Germany: ECountryState.FULLY_REGISTERED,
  Greece: ECountryState.FULLY_REGISTERED,
  Hungary: ECountryState.FULLY_REGISTERED,
  Iceland: ECountryState.FULLY_REGISTERED,
  Ireland: ECountryState.FULLY_REGISTERED,
  Italy: ECountryState.FULLY_REGISTERED,
  Kosovo: ECountryState.FULLY_REGISTERED,
  Latvia: ECountryState.FULLY_REGISTERED,
  Liechtenstein: ECountryState.FULLY_REGISTERED,
  Lithuania: ECountryState.FULLY_REGISTERED,
  Luxembourg: ECountryState.FULLY_REGISTERED,
  Malta: ECountryState.FULLY_REGISTERED,
  Moldova: ECountryState.FULLY_REGISTERED,
  Monaco: ECountryState.FULLY_REGISTERED,
  Montenegro: ECountryState.FULLY_REGISTERED,
  Netherlands: ECountryState.FULLY_REGISTERED,
  NorthMacedonia: ECountryState.FULLY_REGISTERED,
  Norway: ECountryState.FULLY_REGISTERED,
  Poland: ECountryState.FULLY_REGISTERED,
  Portugal: ECountryState.FULLY_REGISTERED,
  Romania: ECountryState.FULLY_REGISTERED,
  Russia: ECountryState.FULLY_REGISTERED,
  SanMarino: ECountryState.FULLY_REGISTERED,
  Serbia: ECountryState.FULLY_REGISTERED,
  Slovakia: ECountryState.FULLY_REGISTERED,
  Slovenia: ECountryState.FULLY_REGISTERED,
  Spain: ECountryState.FULLY_REGISTERED,
  Sweden: ECountryState.FULLY_REGISTERED,
  Switzerland: ECountryState.FULLY_REGISTERED,
  Ukraine: ECountryState.FULLY_REGISTERED,
  UnitedKingdom: ECountryState.FULLY_REGISTERED,
  VaticanCity: ECountryState.FULLY_REGISTERED,

  // Americas
  AntiguaAndBarbuda: ECountryState.ENTITY,
  Argentina: ECountryState.ENTITY,
  Bahamas: ECountryState.ENTITY,
  Barbados: ECountryState.ENTITY,
  Belize: ECountryState.ENTITY,
  Bolivia: ECountryState.ENTITY,
  Brazil: ECountryState.ENTITY,
  Canada: ECountryState.FULLY_REGISTERED,
  Chile: ECountryState.FULLY_REGISTERED,
  Colombia: ECountryState.ENTITY,
  CostaRica: ECountryState.ENTITY,
  Cuba: ECountryState.ENTITY,
  Dominica: ECountryState.ENTITY,
  DominicanRepublic: ECountryState.ENTITY,
  Ecuador: ECountryState.ENTITY,
  ElSalvador: ECountryState.ENTITY,
  Grenada: ECountryState.ENTITY,
  Guatemala: ECountryState.ENTITY,
  Guyana: ECountryState.ENTITY,
  Haiti: ECountryState.ENTITY,
  Honduras: ECountryState.ENTITY,
  Jamaica: ECountryState.ENTITY,
  Mexico: ECountryState.ENTITY,
  Nicaragua: ECountryState.ENTITY,
  Panama: ECountryState.ENTITY,
  Paraguay: ECountryState.ENTITY,
  Peru: ECountryState.ENTITY,
  SaintKittsAndNevis: ECountryState.ENTITY,
  SaintLucia: ECountryState.ENTITY,
  SaintVincentAndTheGrenadines: ECountryState.ENTITY,
  Suriname: ECountryState.ENTITY,
  TrinidadAndTobago: ECountryState.ENTITY,
  UnitedStates: ECountryState.FULLY_REGISTERED,
  Uruguay: ECountryState.ENTITY,
  Venezuela: ECountryState.ENTITY,

  // Asia
  Afghanistan: ECountryState.ENTITY,
  Armenia: ECountryState.ENTITY,
  Azerbaijan: ECountryState.ENTITY,
  Bahrain: ECountryState.ENTITY,
  Bangladesh: ECountryState.ENTITY,
  Bhutan: ECountryState.ENTITY,
  Brunei: ECountryState.ENTITY,
  Cambodia: ECountryState.ENTITY,
  China: ECountryState.FULLY_REGISTERED,
  Georgia: ECountryState.ENTITY,
  India: ECountryState.ENTITY,
  Indonesia: ECountryState.ENTITY,
  Iran: ECountryState.ENTITY,
  Iraq: ECountryState.ENTITY,
  Israel: ECountryState.FULLY_REGISTERED,
  Japan: ECountryState.FULLY_REGISTERED,
  Jordan: ECountryState.ENTITY,
  Kazakhstan: ECountryState.ENTITY,
  Kuwait: ECountryState.ENTITY,
  Kyrgyzstan: ECountryState.ENTITY,
  Laos: ECountryState.ENTITY,
  Lebanon: ECountryState.ENTITY,
  Malaysia: ECountryState.ENTITY,
  Maldives: ECountryState.ENTITY,
  Mongolia: ECountryState.ENTITY,
  Myanmar: ECountryState.ENTITY,
  Nepal: ECountryState.ENTITY,
  NorthKorea: ECountryState.ENTITY,
  Oman: ECountryState.ENTITY,
  Pakistan: ECountryState.ENTITY,
  Palestine: ECountryState.ENTITY,
  Philippines: ECountryState.ENTITY,
  Qatar: ECountryState.ENTITY,
  SaudiArabia: ECountryState.ENTITY,
  Singapore: ECountryState.FULLY_REGISTERED,
  SouthKorea: ECountryState.FULLY_REGISTERED,
  SriLanka: ECountryState.ENTITY,
  Syria: ECountryState.ENTITY,
  Taiwan: ECountryState.FULLY_REGISTERED,
  Tajikistan: ECountryState.ENTITY,
  Thailand: ECountryState.ENTITY,
  TimorLeste: ECountryState.ENTITY,
  Turkey: ECountryState.ENTITY,
  Turkmenistan: ECountryState.ENTITY,
  UnitedArabEmirates: ECountryState.ENTITY,
  Uzbekistan: ECountryState.ENTITY,
  Vietnam: ECountryState.ENTITY,
  Yemen: ECountryState.ENTITY,
};

const CONNECTIONS: Connection[] = [
  { from: "Afghanistan", to: "United States of America", color: "#7265e6" },
  { from: "Albania", to: "United States of America", color: "#7265e6" },
  { from: "Algeria", to: "United States of America", color: "#7265e6" },
  { from: "Andorra", to: "United States of America", color: "#7265e6" },
  { from: "Angola", to: "United States of America", color: "#7265e6" },
  { from: "Argentina", to: "United States of America", color: "#7265e6" },
  { from: "Armenia", to: "United States of America", color: "#7265e6" },
  { from: "Australia", to: "United States of America", color: "#7265e6" },
  { from: "Azerbaijan", to: "United States of America", color: "#7265e6" },
  { from: "Bahamas", to: "United States of America", color: "#7265e6" },
  { from: "Bahrain", to: "United States of America", color: "#7265e6" },
  { from: "Bangladesh", to: "United States of America", color: "#7265e6" },
  { from: "Belarus", to: "United States of America", color: "#7265e6" },
  { from: "Bolivia", to: "United States of America", color: "#7265e6" },
  {
    from: "Bosnia and Herzegovina",
    to: "United States of America",
    color: "#7265e6",
  },
  { from: "Brazil", to: "United States of America", color: "#7265e6" },
  { from: "Canada", to: "United States of America", color: "#7265e6" },
  { from: "China", to: "United States of America", color: "#7265e6" },
  { from: "Colombia", to: "United States of America", color: "#7265e6" },
  { from: "Cuba", to: "United States of America", color: "#7265e6" },
  { from: "Egypt", to: "United States of America", color: "#7265e6" },
  { from: "Georgia", to: "United States of America", color: "#7265e6" },
  { from: "India", to: "United States of America", color: "#7265e6" },
  { from: "Indonesia", to: "United States of America", color: "#7265e6" },
  { from: "Iran", to: "United States of America", color: "#7265e6" },
  { from: "Iraq", to: "United States of America", color: "#7265e6" },
  { from: "Israel", to: "United States of America", color: "#7265e6" },
  { from: "Japan", to: "United States of America", color: "#7265e6" },
  { from: "Kazakhstan", to: "United States of America", color: "#7265e6" },
  { from: "Kenya", to: "United States of America", color: "#7265e6" },
  { from: "Mexico", to: "United States of America", color: "#7265e6" },
  { from: "Morocco", to: "United States of America", color: "#7265e6" },
  { from: "New Zealand", to: "United States of America", color: "#7265e6" },
  { from: "Nigeria", to: "United States of America", color: "#7265e6" },
  { from: "Norway", to: "United States of America", color: "#7265e6" },
  { from: "Pakistan", to: "United States of America", color: "#7265e6" },
  { from: "Peru", to: "United States of America", color: "#7265e6" },
  { from: "Russia", to: "United States of America", color: "#7265e6" },
  { from: "Saudi Arabia", to: "United States of America", color: "#7265e6" },
  { from: "Serbia", to: "United States of America", color: "#7265e6" },
  { from: "Singapore", to: "United States of America", color: "#7265e6" },
  { from: "South Africa", to: "United States of America", color: "#7265e6" },
  { from: "South Korea", to: "United States of America", color: "#7265e6" },
  { from: "Switzerland", to: "United States of America", color: "#7265e6" },
  { from: "Syria", to: "United States of America", color: "#7265e6" },
  { from: "Turkey", to: "United States of America", color: "#7265e6" },
  { from: "Ukraine", to: "United States of America", color: "#7265e6" },
  {
    from: "United Arab Emirates",
    to: "United States of America",
    color: "#7265e6",
  },
  { from: "United Kingdom", to: "United States of America", color: "#7265e6" },
  { from: "Uzbekistan", to: "United States of America", color: "#7265e6" },
  { from: "Vietnam", to: "United States of America", color: "#7265e6" },
  { from: "Austria", to: "Cyprus", color: "#3498db" },
  { from: "Belgium", to: "Cyprus", color: "#3498db" },
  { from: "Bulgaria", to: "Cyprus", color: "#3498db" },
  { from: "Croatia", to: "Cyprus", color: "#3498db" },
  { from: "Czech Republic", to: "Cyprus", color: "#3498db" },
  { from: "Denmark", to: "Cyprus", color: "#3498db" },
  { from: "Estonia", to: "Cyprus", color: "#3498db" },
  { from: "Finland", to: "Cyprus", color: "#3498db" },
  { from: "France", to: "Cyprus", color: "#3498db" },
  { from: "Germany", to: "Cyprus", color: "#3498db" },
  { from: "Greece", to: "Cyprus", color: "#3498db" },
  { from: "Hungary", to: "Cyprus", color: "#3498db" },
  { from: "Ireland", to: "Cyprus", color: "#3498db" },
  { from: "Italy", to: "Cyprus", color: "#3498db" },
  { from: "Latvia", to: "Cyprus", color: "#3498db" },
  { from: "Lithuania", to: "Cyprus", color: "#3498db" },
  { from: "Luxembourg", to: "Cyprus", color: "#3498db" },
  { from: "Malta", to: "Cyprus", color: "#3498db" },
  { from: "Netherlands", to: "Cyprus", color: "#3498db" },
  { from: "Poland", to: "Cyprus", color: "#3498db" },
  { from: "Portugal", to: "Cyprus", color: "#3498db" },
  { from: "Romania", to: "Cyprus", color: "#3498db" },
  { from: "Slovakia", to: "Cyprus", color: "#3498db" },
  { from: "Slovenia", to: "Cyprus", color: "#3498db" },
  { from: "Spain", to: "Cyprus", color: "#3498db" },
  { from: "Sweden", to: "Cyprus", color: "#3498db" },
];

type Position = {
  coordinates: [number, number];
  zoom: number;
};

const adjustLineCoordinates = (
  from: [number, number],
  to: [number, number]
): [[number, number], [number, number]] => {
  const [fromLong, fromLat] = from;
  const [toLong, toLat] = to;

  // Calculate the difference in longitude
  let diffLong = toLong - fromLong;

  // Try different combinations of adjustments to find the shortest path
  const possibilities = [
    [fromLong, toLong], // original
    [fromLong, toLong - 360], // adjust destination west
    [fromLong, toLong + 360], // adjust destination east
    [fromLong - 360, toLong], // adjust source west
    [fromLong + 360, toLong], // adjust source east
  ];

  // Find the combination with the smallest absolute difference
  let shortestDiff = Math.abs(diffLong);
  let bestCoords = [fromLong, toLong];

  possibilities.forEach(([adjustedFrom, adjustedTo]) => {
    const diff = Math.abs(adjustedTo - adjustedFrom);
    if (diff < shortestDiff) {
      shortestDiff = diff;
      bestCoords = [adjustedFrom, adjustedTo];
    }
  });

  return [
    [bestCoords[0], fromLat],
    [bestCoords[1], toLat],
  ];
};

const HOVER_DEBOUNCE_TIME = 100;

const Mapping = () => {
  const [geoData, setGeoData] = useState(null);
  const [tooltip, setTooltip] = useState<TooltipData>(null);
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  // Add position state
  const [position, setPosition] = useState<Position>({
    coordinates: [0, 0],
    zoom: 1,
  });
  const [hoveredElement, setHoveredElement] = useState<{
    type: "country" | "line";
    id: string;
  } | null>(null);

  useEffect(() => {
    fetch("https://unpkg.com/world-atlas@2.0.2/countries-50m.json")
      .then((response) => response.json())
      .then((topology) => {
        const geoJson = feature(topology, topology.objects.countries);
        setGeoData(geoJson as any);
      });
  }, []);

  const handleMoveEnd = (position: Position) => {
    setPosition(position);
  };

  // Replace the handleMouseEnter function
  const handleMouseEnter = (geo: any, evt: any) => {
    const name =
      geo.properties?.name ||
      geo.properties?.NAME ||
      geo.properties?.ADMIN ||
      "Unknown";
    setHoveredCountry(name);
    setHoveredElement({ type: "country", id: name });
    setTooltip({
      name: name,
      coordinates: {
        x: evt.clientX,
        y: evt.clientY,
      },
    });
  };

  // Replace the handleMouseLeave function
  const handleMouseLeave = () => {
    setHoveredCountry(null);
    setHoveredElement(null);
    setTooltip(null);
  };

  // Add debounced handlers using useMemo to maintain reference
  const debouncedLineMouseEnter = useMemo(
    () =>
      debounce((connection: Connection, evt: React.MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        const lineId = `${connection.from}-${connection.to}`;
        if (hoveredElement?.type === "line" && hoveredElement.id === lineId)
          return;
        setHoveredElement({
          type: "line",
          id: lineId,
        });
      }, HOVER_DEBOUNCE_TIME),
    [hoveredElement]
  );

  const debouncedLineMouseLeave = useMemo(
    () =>
      debounce((evt: React.MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        setHoveredElement({
          type: "country",
          id: hoveredCountry || "",
        });
      }, HOVER_DEBOUNCE_TIME),
    [hoveredCountry]
  );

  // Clean up debounced functions on unmount
  useEffect(() => {
    return () => {
      debouncedLineMouseEnter.cancel();
      debouncedLineMouseLeave.cancel();
    };
  }, [debouncedLineMouseEnter, debouncedLineMouseLeave]);

  if (!geoData) return null;

  return (
    <Box
      border={1}
      borderColor="divider"
      borderRadius={1}
      p={0}
      position="relative"
    >
      <Fade in={true} timeout={500}>
        <Stack
          direction="row"
          style={{ width: "100%" }}
          maxHeight="600px"
          overflow="hidden"
        >
          <Box width="70%">
            <ComposableMap {...mapConfig}>
              <ZoomableGroup
                zoom={position.zoom}
                center={position.coordinates}
                onMoveEnd={handleMoveEnd}
              >
                <Geographies geography={geoData}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          COUNTRY_STATES[geo.properties.name] ===
                          ECountryState.FULLY_REGISTERED
                            ? "#f0d7ff"
                            : COUNTRY_STATES[geo.properties.name] ===
                              ECountryState.ENTITY
                            ? "#fcccff"
                            : "#EAEAEC"
                        }
                        style={{
                          default: { outline: "none" },
                          hover: { outline: "none", fill: "#F5F5F5" },
                          pressed: { outline: "none" },
                        }}
                        onMouseEnter={(evt) => handleMouseEnter(geo, evt)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(evt) => {
                          setSelectedCountry(geo.properties.name);
                        }}
                      />
                    ))
                  }
                </Geographies>

                {/* Add Lines for connections */}
                {hoveredCountry &&
                  CONNECTIONS.map((connection, index) => {
                    if (connection.from !== hoveredCountry) {
                      return null;
                    }

                    const fromCoords = COUNTRY_COORDINATES[connection.from];
                    const toCoords = COUNTRY_COORDINATES[connection.to];

                    if (!fromCoords || !toCoords) return null;

                    const [adjustedFrom, adjustedTo] = adjustLineCoordinates(
                      fromCoords,
                      toCoords
                    );

                    const lineId = `${connection.from}-${connection.to}`;
                    const isLineHovered =
                      hoveredElement?.type === "line" &&
                      hoveredElement.id === lineId;

                    return (
                      <g key={`${connection.from}-${connection.to}-${index}`}>
                        <Line
                          from={adjustedFrom}
                          to={adjustedTo}
                          stroke={connection.color || "#7265e6"}
                          strokeWidth={isLineHovered ? 2 : 1}
                          strokeOpacity={0.8}
                          strokeLinecap="round"
                          style={{
                            pointerEvents: "none",
                            strokeLinejoin: "round",
                            vectorEffect: "non-scaling-stroke",

                            zIndex: -1,
                          }}
                        />
                      </g>
                    );
                  })}
              </ZoomableGroup>
            </ComposableMap>
          </Box>
          <Box p={2} bgcolor="#FAFAFA" width="30%">
            <MainCard
              title={selectedCountry || "No Country Selected"}
              sx={{ height: "100%", overflowY: "auto" }}
            >
              <ul>
                {selectedCountry &&
                  CONNECTIONS.filter((conn) => conn.to === selectedCountry).map(
                    (conn) => <li key={conn.from}>← {conn.from}</li>
                  )}

                {CONNECTIONS.filter(
                  (conn) => conn.from === selectedCountry
                ).map((conn) => (
                  <li key={`${conn.to}-${conn.from}`}>→ {conn.to}</li>
                ))}
              </ul>
            </MainCard>
          </Box>
        </Stack>
      </Fade>

      {/* Tooltip */}
      {tooltip && (
        <div
          style={{
            position: "fixed",
            left: tooltip.coordinates.x + 20,
            top: tooltip.coordinates.y + 20,
            background: "white",
            padding: "5px 10px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            pointerEvents: "none",
            zIndex: 1000,
          }}
        >
          <div>
            <strong>{tooltip.name}</strong>
          </div>
          {CONNECTIONS.filter((conn) => conn.from === tooltip.name).map(
            (conn) => (
              <div key={`${conn.from}-${conn.to}`}>→ {conn.to}</div>
            )
          )}
        </div>
      )}
    </Box>
  );
};

export default memo(Mapping);
