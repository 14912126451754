import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import {
  useTable,
  usePagination,
  Column,
  Row,
  Cell,
  HeaderGroup,
} from "react-table";
import { useState, useMemo, useRef, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

// Add these components/utilities if they're custom
import MainCard from "components/MainCard";
import usePublishers from "hooks/usePublishers";
import { Publisher } from "types/publisher";
import countries from "data/countries";
import { EPaymentMethod } from "./types";
import useRoutingRules, { RoutingRulePayload } from "hooks/useRoutingRules";
import { enqueueSnackbar } from "notistack";

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const theme = useTheme();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
        },
      },
      usePagination
    );

  return (
    <>
      <MainCard title="" content={false}>
        <Stack spacing={3}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup) => (
                    <TableCell
                      {...column.getHeaderProps([
                        { className: column.className },
                      ])}
                      style={{ width: column.cellWidth }}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row: Row, i: number) => {
                prepareRow(row);
                return (
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.lighter, 0.35)
                        : "inherit",
                    }}
                  >
                    {row.cells.map((cell: Cell) => (
                      <TableCell
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                        style={{ width: cell.column.cellWidth }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </MainCard>
    </>
  );
}

const CountrySelectCell = ({
  value,
  row,
  onChange,
}: {
  value: Array<{
    twoLetterCode: string;
    name: string;
  }>;
  row: any;
  onChange?: (
    countries: Array<{ twoLetterCode: string; name: string }>
  ) => void;
}) => {
  const [selectedCountries, setSelectedCountries] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const filteredOptions = countries.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event: any) => {
    const selectedIds = event.target.value as string[];
    const newSelectedCountries = selectedIds.map((id) => {
      const option = countries.find((opt) => opt.twoLetterCode === id);
      return {
        twoLetterCode: option!.twoLetterCode,
        name: option!.name,
      };
    });

    setSelectedCountries(newSelectedCountries);
    onChange?.(newSelectedCountries);
  };

  return (
    <Select
      multiple
      displayEmpty
      value={selectedCountries.map((country) => country.twoLetterCode)}
      onChange={handleChange}
      input={<OutlinedInput size="small" />}
      renderValue={(selected) =>
        selectedCountries.length === 0 ? (
          <i>Select Countries</i>
        ) : selectedCountries.length > 1 ? (
          `${selectedCountries.length} countries selected`
        ) : (
          selectedCountries[0]?.name
        )
      }
      sx={{
        width: "170px",
        maxWidth: "170px",
        overflow: "hidden",
      }}
      onOpen={() => {
        setSearchTerm("");
        setIsOpen(true);
      }}
      onClose={() => setIsOpen(false)}
    >
      <MenuItem value="Select Countries" disabled>
        Select Countries
      </MenuItem>
      <Box sx={{ p: 1 }}>
        <OutlinedInput
          inputRef={searchInputRef}
          size="small"
          placeholder="Search countries..."
          fullWidth
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation();
            // Prevent arrow keys from moving to options
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
        />
      </Box>
      {filteredOptions.map((option) => (
        <MenuItem key={option.twoLetterCode} value={option.twoLetterCode}>
          <Checkbox
            checked={selectedCountries.some(
              (item) => item.twoLetterCode === option.twoLetterCode
            )}
          />
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

const PublisherSelectCell = ({
  value,
  publisherData,
  isLoading,
  row,
  onChange,
}: {
  value: Array<{ gameName: string; gameId: string }>;
  publisherData: Publisher[];
  isLoading: boolean;
  row: any;
  onChange?: (games: Array<{ gameName: string; gameId: string }>) => void;
}) => {
  const [selectedGames, setSelectedGames] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  // Move groupedOptions to useMemo with publisherData dependency
  const groupedOptions = useMemo(() => {
    const grouped = publisherData.reduce((acc, publisher) => {
      const companyId = publisher.companyId || "uncategorized";
      if (!acc[companyId]) {
        acc[companyId] = { companyName: publisher.companyName, publishers: [] };
      }
      acc[companyId].publishers.push({
        gameName: publisher.companyName,
        gameId: publisher._id,
      });
      return acc;
    }, {} as Record<string, { companyName: string; publishers: Array<{ gameName: string; gameId: string }> }>);

    return grouped;
  }, [publisherData]); // Only recalculate when publisherData changes

  // Add filtered groups calculation
  const filteredGroupedOptions = useMemo(() => {
    if (!searchTerm) return groupedOptions;

    const searchLower = searchTerm.toLowerCase();
    return Object.entries(groupedOptions).reduce((acc, [groupId, group]) => {
      const filteredPublishers = group.publishers.filter((publisher) =>
        publisher.gameName.toLowerCase().includes(searchLower)
      );

      if (filteredPublishers.length > 0) {
        acc[groupId] = {
          ...group,
          publishers: filteredPublishers,
        };
      }
      return acc;
    }, {} as typeof groupedOptions);
  }, [groupedOptions, searchTerm]);

  const handleChange = (event: any) => {
    const selectedIds = event.target.value as string[];
    const newSelectedGames = selectedIds
      .map((id) => {
        // Search through all publishers in all groups to find the matching game
        for (const group of Object.values(groupedOptions)) {
          const option = group.publishers.find((pub) => pub.gameId === id);
          if (option) {
            return {
              gameId: option.gameId,
              gameName: option.gameName,
            };
          }
        }
        return null;
      })
      .filter(
        (game): game is { gameId: string; gameName: string } => game !== null
      );

    setSelectedGames(newSelectedGames);
    onChange?.(newSelectedGames);
  };

  // Add useEffect for search input focus
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <Select
      multiple
      displayEmpty
      disabled={isLoading}
      value={selectedGames.map((game) => game.gameId)}
      onChange={handleChange}
      input={<OutlinedInput size="small" />}
      renderValue={(selected) =>
        selectedGames.length > 1
          ? `${selectedGames.length} games selected`
          : selectedGames[0]?.gameName || <i>Select Games</i>
      }
      size="small"
      sx={{
        width: "170px",
        maxWidth: "170px",
        overflow: "hidden",
      }}
      onOpen={() => {
        setSearchTerm("");
        setIsOpen(true);
      }}
      onClose={() => setIsOpen(false)}
    >
      <MenuItem value="Select Games" disabled>
        Select Games
      </MenuItem>
      <Box sx={{ p: 1 }} onClick={(e) => e.stopPropagation()}>
        <OutlinedInput
          inputRef={searchInputRef}
          size="small"
          placeholder="Search games..."
          fullWidth
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.stopPropagation();
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
        />
      </Box>
      {Object.entries(filteredGroupedOptions).map(([groupId, group]) => (
        <Box key={groupId}>
          <Typography
            variant="subtitle1"
            textTransform="capitalize"
            sx={{
              px: 2,
              py: 1,
              bgcolor: "background.default",
              color: "text.primary",
            }}
          >
            {group.companyName || "Other"}
          </Typography>
          {group.publishers.map((option) => (
            <MenuItem
              key={option.gameId}
              value={option.gameId}
              onClick={(e) => {
                e.stopPropagation();
                const currentValue = selectedGames.map((game) => game.gameId);
                const newValue = currentValue.includes(option.gameId)
                  ? currentValue.filter((id) => id !== option.gameId)
                  : [...currentValue, option.gameId];
                handleChange({ target: { value: newValue } });
              }}
            >
              <Checkbox
                checked={selectedGames.some(
                  (item) => item.gameId === option.gameId
                )}
              />
              <ListItemText primary={option.gameName} />
            </MenuItem>
          ))}
        </Box>
      ))}
    </Select>
  );
};

const SubmidSelectCell = ({
  value,
  row,
  onChange,
}: {
  value: any;
  row: any;
  onChange?: (submid: string) => void;
}) => {
  const [selectedSubmid, setSelectedSubmid] = useState("");
  const { getPaymentProviders } = useRoutingRules(EPaymentMethod.CARD);
  const paymentProviders = getPaymentProviders?.data?.data;

  const handleSubmidChange = (event: any) => {
    setSelectedSubmid(event.target.value);
    onChange?.(event.target.value);
  };

  return (
    <Select
      value={selectedSubmid}
      onChange={handleSubmidChange}
      size="small"
      sx={{
        width: "130px",
        maxWidth: "130px",
        overflow: "hidden",
      }}
      disabled={getPaymentProviders?.isLoading}
      displayEmpty
    >
      <MenuItem value="" disabled>
        <i>Select a PSP</i>
      </MenuItem>
      {paymentProviders
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((submid) => (
          <MenuItem key={submid.id} value={submid.id}>
            {submid.name}
          </MenuItem>
        ))}
    </Select>
  );
};

const RoutingRulesDrawer = ({
  open,
  onClose,
  refetch,
  editMode = false,
}: {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  editMode?: boolean;
}) => {
  const { getPublishers } = usePublishers();
  const { addRoutingRuleMutation } = useRoutingRules(EPaymentMethod.CARD);
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [selectedGames, setSelectedGames] = useState<any[]>([]);
  const [selectedSubmid, setSelectedSubmid] = useState<string>("");
  const { getPaymentProviders } = useRoutingRules(EPaymentMethod.CARD);
  const onPublisherChange = (games: any[]) => {
    setSelectedGames(games);
  };

  const onCountryChange = (
    countries: Array<{ twoLetterCode: string; name: string }>
  ) => {
    setSelectedCountries(countries);
  };

  const onSubmidChange = (submid: string) => {
    setSelectedSubmid(submid);
  };

  const onAddRule = () => {
    // Create Cartesian product of countries and games
    const rules: RoutingRulePayload[] = selectedCountries.flatMap((country) =>
      selectedGames.map((game) => ({
        countryCode: countries.find(
          (c) => c.twoLetterCode === country.twoLetterCode
        )?.threeLetterCode!,
        paymentMethodName: EPaymentMethod.CARD,
        publisherId: game.gameId,
        prioritizedSubMid: selectedSubmid,
      }))
    );

    addRoutingRuleMutation.mutate(rules, {
      onSuccess: () => {
        enqueueSnackbar("Rule added successfully", {
          variant: "success",
        });
        refetch();
        onClose();
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Error adding rule", {
          variant: "error",
        });
      },
    });
  };

  const drawerColumns = useMemo(
    () => [
      {
        Header: "Payment Provider",
        accessor: "submid",
        cellWidth: "100px",
        Cell: ({ value, row }: { value: any; row: any }) => (
          <SubmidSelectCell value={value} row={row} onChange={onSubmidChange} />
        ),
      },
      {
        Header: "Countries",
        accessor: "countries",
        Cell: ({ value, row }: { value: any; row: any }) => (
          <CountrySelectCell
            value={value}
            onChange={onCountryChange}
            row={row}
          />
        ),
        cellWidth: "100px",
      },
      {
        Header: "Games",
        accessor: "games",
        Cell: ({ value, row }: { value: any; row: any }) => (
          <PublisherSelectCell
            publisherData={getPublishers?.data || []}
            isLoading={getPublishers?.isLoading || false}
            onChange={onPublisherChange}
            value={value}
            row={row}
          />
        ),
        cellWidth: "100px",
      },
    ],
    [getPublishers?.data, getPublishers?.isLoading]
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: {
            xs: "100%",
            sm: "75%",
            md: editMode ? "450px" : "650px",
          },
          padding: 3,
        },
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          {editMode ? "Edit Rule(s)" : "Add a new Rule"}
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              {!editMode ? (
                <ReactTable
                  columns={drawerColumns as any}
                  data={
                    [
                      {
                        id: "1",
                        submid: "Nuvei",
                        countries: [],
                        games: [],
                      },
                    ] as any
                  }
                />
              ) : (
                <Stack>
                  <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Select new PSP:
                  </Typography>
                  <Select
                    size="small"
                    value={selectedSubmid}
                    onChange={(e) => setSelectedSubmid(e.target.value)}
                    disabled={getPaymentProviders?.isLoading}
                  >
                    <MenuItem value="" disabled>
                      <i>Select a PSP</i>
                    </MenuItem>
                    {getPaymentProviders?.data?.data
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((provider) => (
                        <MenuItem key={provider.id} value={provider.id}>
                          {provider.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" mt={2} gap={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              sx={{
                flex: 1,
              }}
              disabled={
                selectedCountries.length === 0 ||
                selectedGames.length === 0 ||
                selectedSubmid === ""
              }
              onClick={onAddRule}
            >
              Add Rule
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                flex: 1,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default RoutingRulesDrawer;
