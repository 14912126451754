import { useEffect, useMemo, useState } from "react";

// material-ui
import {
  Box,
  Chip,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { Copy } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import useDisputes, { Dispute } from "hooks/useDisputes";
import { Button } from "@mui/material";
import { LuFullscreen } from "react-icons/lu";

// Add this constant near the top of the file, after imports
const SMALL_FONT_SIZE = "12px";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  isFullscreen,
  setIsFullscreen,
}: {
  columns: Column[];
  data: [];
  isFullscreen: boolean;
  setIsFullscreen: (isFullscreen: boolean) => void;
}) {
  const [selectedDispute, setSelectedDispute] = useState<Dispute | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 150);

  return (
    <div>
      <Drawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setTimeout(() => {
            setSelectedDispute(null);
          }, 500);
        }}
        anchor="right"
        PaperProps={{
          sx: {
            width: "350px",
          },
        }}
      >
        <Stack direction="column" spacing={2} p={4}>
          <Typography variant="h4" color="primary">
            Dispute {selectedDispute?.disputeId}
          </Typography>
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Amount:</strong> $
              {selectedDispute?.amountTotal.toFixed(2)}
            </Typography>
            {selectedDispute?.chargebackFee && (
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Chargeback Fee:</strong> $
                {selectedDispute.chargebackFee.toFixed(2)}
              </Typography>
            )}
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Created:</strong>{" "}
              {selectedDispute?.createdAt &&
                new Date(selectedDispute.createdAt).toLocaleString(undefined, {
                  hour12: false,
                })}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Order ID:</strong> {selectedDispute?.orderId}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Payment ID:</strong> {selectedDispute?.paymentId}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Payment Method:</strong>{" "}
              {selectedDispute?.paymentMethod
                ? `${selectedDispute.paymentMethod.primaryMethod}${
                    selectedDispute.paymentMethod.metadata?.cardLastFourDigits
                      ? ` (*${selectedDispute.paymentMethod.metadata.cardLastFourDigits})`
                      : ""
                  }`
                : "-"}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Payment Provider:</strong>{" "}
              {selectedDispute?.paymentProviderName}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Player ID:</strong> {selectedDispute?.playerId}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Publisher:</strong> {selectedDispute?.publisherName}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>SubMID ID:</strong> {selectedDispute?.subMidId}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Transaction Status:</strong>{" "}
              {selectedDispute?.transactionStatus || "Unknown"}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Due By:</strong>{" "}
              {(selectedDispute?.disputeDueBy &&
                new Date(selectedDispute.disputeDueBy).toLocaleDateString(
                  "en-GB"
                )) ||
                "N/A"}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>PSP Reason:</strong> {selectedDispute?.pspReason || "N/A"}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>PSP Reason Code:</strong>{" "}
              {selectedDispute?.pspReasonCode || "N/A"}
            </Typography>
            <Typography variant="subtitle1" fontWeight="normal">
              <strong>Last Updated:</strong>{" "}
              {selectedDispute?.updatedAt &&
                new Date(selectedDispute.updatedAt).toLocaleString(undefined, {
                  hour12: false,
                })}
            </Typography>
          </Stack>
        </Stack>
      </Drawer>
      <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsFullscreen(!isFullscreen);
            document.querySelector(".sidebar")?.classList.toggle("hidden");
            document.querySelector(".header")?.classList.toggle("hidden");
          }}
        >
          <LuFullscreen />
        </Button>
      </Stack>

      <Table {...getTableProps()} size="small">
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    minWidth: 150,
                    overflow: "hidden",
                    fontSize: isFullscreen ? SMALL_FONT_SIZE : undefined,
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    minWidth: 150,
                    overflow: "hidden",
                    fontSize: isFullscreen ? SMALL_FONT_SIZE : undefined,
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => {
                    setSelectedDispute(row.original as Dispute);
                    setIsDrawerOpen(true);
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "action.hover" },
                    padding: 0,
                  }}
                >
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                      style={{
                        fontSize: isFullscreen ? SMALL_FONT_SIZE : undefined,
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
    </div>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const DisputesTable = () => {
  const { getDisputes } = useDisputes();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    getDisputes.mutate();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Dispute ID",
        accessor: "disputeId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Amount Total (in USD)",
        accessor: "amountTotal",
        Cell: ({ value }: { value: number }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            $
            {(value / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        ),
      },
      {
        Header: "Chargeback Fee",
        accessor: "chargebackFee",
        Cell: ({ value }: { value: number | undefined }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value
              ? `$${(value / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : "-"}
          </Typography>
        ),
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {new Date(value).toLocaleDateString("en-GB")}
          </Typography>
        ),
      },
      {
        Header: "Order ID",
        accessor: "orderId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied Order ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
        Cell: ({ value }: { value: any }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value ? `${value.primaryMethod}` : "-"}
          </Typography>
        ),
      },
      {
        Header: "Payment 4 Last Digits",
        accessor: "cardLastFourDigits",
        Cell: ({ row }: { row: Row }) => {
          return (
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {(row.original as any)?.paymentMethod?.metadata
                ?.cardLastFourDigits || "-"}
            </Typography>
          );
        },
      },
      {
        Header: "Payment Provider",
        accessor: "paymentProviderName",
      },
      {
        Header: "Player ID",
        accessor: "playerId",
      },
      {
        Header: "Game Name",
        accessor: "publisherName",
      },
      {
        Header: "Transaction Status",
        accessor: "transactionStatus",
        Cell: ({ value }: { value: string | undefined }) => (
          <Chip
            label={value || "Unknown"}
            size="small"
            variant="light"
            color={
              value === "COMPLETED"
                ? "success"
                : value === "FAILED"
                ? "error"
                : "default"
            }
          />
        ),
      },
      {
        Header: "Due By",
        accessor: "disputeDueBy",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value ? new Date(value).toLocaleDateString("en-GB") : "-"}
          </Typography>
        ),
      },
      {
        Header: "PSP Reason",
        accessor: "pspReason",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value}
          </Typography>
        ),
      },
      {
        Header: "PSP Reason Code",
        accessor: "pspReasonCode",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value}
          </Typography>
        ),
      },
      {
        Header: "Updated At",
        accessor: "updatedAt",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {new Date(value).toLocaleString(undefined, { hour12: false })}
          </Typography>
        ),
      },
    ],
    [isFullscreen]
  );

  return !getDisputes.isPending && getDisputes.data?.data ? (
    <>
      <Fade key={"publishers"} in={true} timeout={500}>
        <MainCard
          content={false}
          style={
            isFullscreen
              ? {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  padding: 2,
                  zIndex: 1000,
                }
              : undefined
          }
        >
          <ScrollX
            style={{
              height: "100%",
            }}
          >
            <ReactTable
              isFullscreen={isFullscreen}
              setIsFullscreen={setIsFullscreen}
              columns={columns as Column[]}
              data={(getDisputes.data?.data as any).disputesData || []}
            />
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  ) : (
    <>
      <Skeleton variant="rounded" height={120} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
    </>
  );
};

export default DisputesTable;
