import { EPermissionAction, EPermissionSection } from "./types";

export const PERMISSIONS_OPTIONS: Record<
  EPermissionSection,
  EPermissionAction[]
> = {
  [EPermissionSection.USERS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
  [EPermissionSection.COMPANIES]: [
    EPermissionAction.CREATE,
    // EPermissionAction.GET,
    // EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.PUBLISHERS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.PUBLISHERS_USERS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    // EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
  [EPermissionSection.PAYOUTS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
  [EPermissionSection.PAYMENT_METHODS]: [
    // EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.BLOCKED_PLAYERS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
  [EPermissionSection.FEES]: [
    // EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.AUDITS]: [
    // EPermissionAction.CREATE,
    EPermissionAction.GET,
    // EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.ROLES]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
  [EPermissionSection.ADVANCED_SETTINGS]: [
    // EPermissionAction.CREATE,
    EPermissionAction.GET,
    EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.RESTORE_RECEIPTS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    // EPermissionAction.EDIT,
    // EPermissionAction.DELETE,
  ],
  [EPermissionSection.LOGIN_TO_DASHBOARD_AS_SUPER_ADMIN]: [
    EPermissionAction.GET,
  ],
  [EPermissionSection.LOGIN_TO_DASHBOARD_AS_SPECTATOR]: [EPermissionAction.GET],
  [EPermissionSection.DISPUTE_DATA_CENTER]: [EPermissionAction.GET],
  [EPermissionSection.VAT_REPORTS]: [
    EPermissionAction.CREATE,
    EPermissionAction.GET,
    // EPermissionAction.EDIT,
    EPermissionAction.DELETE,
  ],
};
