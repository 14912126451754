export interface CountryType {
  name: string;
  label: string;
  twoLetterCode: string;
  threeLetterCode: string;
  code: string;
  phone: string;
}

// ==============================|| DATA - COUNTRIES ||============================== //

export const countries: readonly CountryType[] = [
  {
    name: "Europe",
    twoLetterCode: "EU",
    threeLetterCode: "EUR",
    code: "150",
    label: "Europe",
    phone: "+381",
  },
  {
    name: "Afghanistan",
    twoLetterCode: "AF",
    threeLetterCode: "AFG",
    code: "004",
    label: "Afghanistan",
    phone: "+93",
  },
  {
    name: "Albania",
    twoLetterCode: "AL",
    threeLetterCode: "ALB",
    code: "008",
    label: "Albania",
    phone: "+355",
  },
  {
    name: "Algeria",
    twoLetterCode: "DZ",
    threeLetterCode: "DZA",
    code: "012",
    label: "Algeria",
    phone: "+213",
  },
  {
    name: "Andorra",
    twoLetterCode: "AD",
    threeLetterCode: "AND",
    code: "020",
    label: "Andorra",
    phone: "+376",
  },
  {
    name: "Angola",
    twoLetterCode: "AO",
    threeLetterCode: "AGO",
    code: "024",
    label: "Angola",
    phone: "+244",
  },
  {
    name: "Antigua and Barbuda",
    twoLetterCode: "AG",
    threeLetterCode: "ATG",
    code: "028",
    label: "Antigua and Barbuda",
    phone: "+1-268",
  },
  {
    name: "Argentina",
    twoLetterCode: "AR",
    threeLetterCode: "ARG",
    code: "032",
    label: "Argentina",
    phone: "+54",
  },
  {
    name: "Armenia",
    twoLetterCode: "AM",
    threeLetterCode: "ARM",
    code: "051",
    label: "Armenia",
    phone: "+374",
  },
  {
    name: "Australia",
    twoLetterCode: "AU",
    threeLetterCode: "AUS",
    code: "036",
    label: "Australia",
    phone: "+61",
  },
  {
    name: "Austria",
    twoLetterCode: "AT",
    threeLetterCode: "AUT",
    code: "040",
    label: "Austria",
    phone: "+43",
  },
  {
    name: "Azerbaijan",
    twoLetterCode: "AZ",
    threeLetterCode: "AZE",
    code: "031",
    label: "Azerbaijan",
    phone: "+994",
  },
  {
    name: "Bahamas",
    twoLetterCode: "BS",
    threeLetterCode: "BHS",
    code: "044",
    label: "Bahamas",
    phone: "+1-242",
  },
  {
    name: "Bahrain",
    twoLetterCode: "BH",
    threeLetterCode: "BHR",
    code: "048",
    label: "Bahrain",
    phone: "+973",
  },
  {
    name: "Bangladesh",
    twoLetterCode: "BD",
    threeLetterCode: "BGD",
    code: "050",
    label: "Bangladesh",
    phone: "+880",
  },
  {
    name: "Barbados",
    twoLetterCode: "BB",
    threeLetterCode: "BRB",
    code: "052",
    label: "Barbados",
    phone: "+1-246",
  },
  {
    name: "Belarus",
    twoLetterCode: "BY",
    threeLetterCode: "BLR",
    code: "112",
    label: "Belarus",
    phone: "+375",
  },
  {
    name: "Belgium",
    twoLetterCode: "BE",
    threeLetterCode: "BEL",
    code: "056",
    label: "Belgium",
    phone: "+32",
  },
  {
    name: "Belize",
    twoLetterCode: "BZ",
    threeLetterCode: "BLZ",
    code: "084",
    label: "Belize",
    phone: "+501",
  },
  {
    name: "Benin",
    twoLetterCode: "BJ",
    threeLetterCode: "BEN",
    code: "204",
    label: "Benin",
    phone: "+229",
  },
  {
    name: "Bhutan",
    twoLetterCode: "BT",
    threeLetterCode: "BTN",
    code: "064",
    label: "Bhutan",
    phone: "+975",
  },
  {
    name: "Bolivia (Plurinational State of)",
    twoLetterCode: "BO",
    threeLetterCode: "BOL",
    code: "068",
    label: "Bolivia",
    phone: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    twoLetterCode: "BA",
    threeLetterCode: "BIH",
    code: "070",
    label: "Bosnia and Herzegovina",
    phone: "+387",
  },
  {
    name: "Botswana",
    twoLetterCode: "BW",
    threeLetterCode: "BWA",
    code: "072",
    label: "Botswana",
    phone: "+267",
  },
  {
    name: "Brazil",
    twoLetterCode: "BR",
    threeLetterCode: "BRA",
    code: "076",
    label: "Brazil",
    phone: "+55",
  },
  {
    name: "Brunei Darussalam",
    twoLetterCode: "BN",
    threeLetterCode: "BRN",
    code: "096",
    label: "Brunei Darussalam",
    phone: "+673",
  },
  {
    name: "Bulgaria",
    twoLetterCode: "BG",
    threeLetterCode: "BGR",
    code: "100",
    label: "Bulgaria",
    phone: "+359",
  },
  {
    name: "Burkina Faso",
    twoLetterCode: "BF",
    threeLetterCode: "BFA",
    code: "854",
    label: "Burkina Faso",
    phone: "+226",
  },
  {
    name: "Burundi",
    twoLetterCode: "BI",
    threeLetterCode: "BDI",
    code: "108",
    label: "Burundi",
    phone: "+257",
  },
  {
    name: "Cabo Verde",
    twoLetterCode: "CV",
    threeLetterCode: "CPV",
    code: "132",
    label: "Cabo Verde",
    phone: "+238",
  },
  {
    name: "Cambodia",
    twoLetterCode: "KH",
    threeLetterCode: "KHM",
    code: "116",
    label: "Cambodia",
    phone: "+855",
  },
  {
    name: "Cameroon",
    twoLetterCode: "CM",
    threeLetterCode: "CMR",
    code: "120",
    label: "Cameroon",
    phone: "+237",
  },
  {
    name: "Canada",
    twoLetterCode: "CA",
    threeLetterCode: "CAN",
    code: "124",
    label: "Canada",
    phone: "+1",
  },
  {
    name: "Central African Republic",
    twoLetterCode: "CF",
    threeLetterCode: "CAF",
    code: "140",
    label: "Central African Republic",
    phone: "+236",
  },
  {
    name: "Chad",
    twoLetterCode: "TD",
    threeLetterCode: "TCD",
    code: "148",
    label: "Chad",
    phone: "+235",
  },
  {
    name: "Chile",
    twoLetterCode: "CL",
    threeLetterCode: "CHL",
    code: "152",
    label: "Chile",
    phone: "+56",
  },
  {
    name: "China",
    twoLetterCode: "CN",
    threeLetterCode: "CHN",
    code: "156",
    label: "China",
    phone: "+86",
  },
  {
    name: "Colombia",
    twoLetterCode: "CO",
    threeLetterCode: "COL",
    code: "170",
    label: "Colombia",
    phone: "+57",
  },
  {
    name: "Comoros",
    twoLetterCode: "KM",
    threeLetterCode: "COM",
    code: "174",
    label: "Comoros",
    phone: "+269",
  },
  {
    name: "Congo (Congo-Brazzaville)",
    twoLetterCode: "CG",
    threeLetterCode: "COG",
    code: "178",
    label: "Congo",
    phone: "+242",
  },
  {
    name: "Congo (Democratic Republic of the)",
    twoLetterCode: "CD",
    threeLetterCode: "COD",
    code: "180",
    label: "Congo (DRC)",
    phone: "+243",
  },
  {
    name: "Costa Rica",
    twoLetterCode: "CR",
    threeLetterCode: "CRI",
    code: "188",
    label: "Costa Rica",
    phone: "+506",
  },
  {
    name: "Croatia",
    twoLetterCode: "HR",
    threeLetterCode: "HRV",
    code: "191",
    label: "Croatia",
    phone: "+385",
  },
  {
    name: "Cuba",
    twoLetterCode: "CU",
    threeLetterCode: "CUB",
    code: "192",
    label: "Cuba",
    phone: "+53",
  },
  {
    name: "Cyprus",
    twoLetterCode: "CY",
    threeLetterCode: "CYP",
    code: "196",
    label: "Cyprus",
    phone: "+357",
  },
  {
    name: "Czechia (Czech Republic)",
    twoLetterCode: "CZ",
    threeLetterCode: "CZE",
    code: "203",
    label: "Czechia",
    phone: "+420",
  },
  {
    name: "Denmark",
    twoLetterCode: "DK",
    threeLetterCode: "DNK",
    code: "208",
    label: "Denmark",
    phone: "+45",
  },
  {
    name: "Djibouti",
    twoLetterCode: "DJ",
    threeLetterCode: "DJI",
    code: "262",
    label: "Djibouti",
    phone: "+253",
  },
  {
    name: "Dominica",
    twoLetterCode: "DM",
    threeLetterCode: "DMA",
    code: "212",
    label: "Dominica",
    phone: "+1-767",
  },
  {
    name: "Dominican Republic",
    twoLetterCode: "DO",
    threeLetterCode: "DOM",
    code: "214",
    label: "Dominican Republic",
    phone: "+1-809, +1-829, +1-849",
  },
  {
    name: "Ecuador",
    twoLetterCode: "EC",
    threeLetterCode: "ECU",
    code: "218",
    label: "Ecuador",
    phone: "+593",
  },
  {
    name: "Egypt",
    twoLetterCode: "EG",
    threeLetterCode: "EGY",
    code: "818",
    label: "Egypt",
    phone: "+20",
  },
  {
    name: "El Salvador",
    twoLetterCode: "SV",
    threeLetterCode: "SLV",
    code: "222",
    label: "El Salvador",
    phone: "+503",
  },
  {
    name: "Equatorial Guinea",
    twoLetterCode: "GQ",
    threeLetterCode: "GNQ",
    code: "226",
    label: "Equatorial Guinea",
    phone: "+240",
  },
  {
    name: "Eritrea",
    twoLetterCode: "ER",
    threeLetterCode: "ERI",
    code: "232",
    label: "Eritrea",
    phone: "+291",
  },
  {
    name: "Estonia",
    twoLetterCode: "EE",
    threeLetterCode: "EST",
    code: "233",
    label: "Estonia",
    phone: "+372",
  },
  {
    name: "Eswatini (fmr. Swaziland)",
    twoLetterCode: "SZ",
    threeLetterCode: "SWZ",
    code: "748",
    label: "Eswatini",
    phone: "+268",
  },
  {
    name: "Ethiopia",
    twoLetterCode: "ET",
    threeLetterCode: "ETH",
    code: "231",
    label: "Ethiopia",
    phone: "+251",
  },
  {
    name: "Fiji",
    twoLetterCode: "FJ",
    threeLetterCode: "FJI",
    code: "242",
    label: "Fiji",
    phone: "+679",
  },
  {
    name: "Finland",
    twoLetterCode: "FI",
    threeLetterCode: "FIN",
    code: "246",
    label: "Finland",
    phone: "+358",
  },
  {
    name: "France",
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    code: "250",
    label: "France",
    phone: "+33",
  },

  {
    name: "Gabon",
    twoLetterCode: "GA",
    threeLetterCode: "GAB",
    code: "266",
    label: "Gabon",
    phone: "+241",
  },
  {
    name: "Gambia",
    twoLetterCode: "GM",
    threeLetterCode: "GMB",
    code: "270",
    label: "Gambia",
    phone: "+220",
  },
  {
    name: "Georgia",
    twoLetterCode: "GE",
    threeLetterCode: "GEO",
    code: "268",
    label: "Georgia",
    phone: "+995",
  },
  {
    name: "Germany",
    twoLetterCode: "DE",
    threeLetterCode: "DEU",
    code: "276",
    label: "Germany",
    phone: "+49",
  },
  {
    name: "Ghana",
    twoLetterCode: "GH",
    threeLetterCode: "GHA",
    code: "288",
    label: "Ghana",
    phone: "+233",
  },
  {
    name: "Greece",
    twoLetterCode: "GR",
    threeLetterCode: "GRC",
    code: "300",
    label: "Greece",
    phone: "+30",
  },
  {
    name: "Grenada",
    twoLetterCode: "GD",
    threeLetterCode: "GRD",
    code: "308",
    label: "Grenada",
    phone: "+1-473",
  },
  {
    name: "Guatemala",
    twoLetterCode: "GT",
    threeLetterCode: "GTM",
    code: "320",
    label: "Guatemala",
    phone: "+502",
  },
  {
    name: "Guinea",
    twoLetterCode: "GN",
    threeLetterCode: "GIN",
    code: "324",
    label: "Guinea",
    phone: "+224",
  },
  {
    name: "Guinea-Bissau",
    twoLetterCode: "GW",
    threeLetterCode: "GNB",
    code: "624",
    label: "Guinea-Bissau",
    phone: "+245",
  },
  {
    name: "Guyana",
    twoLetterCode: "GY",
    threeLetterCode: "GUY",
    code: "328",
    label: "Guyana",
    phone: "+592",
  },
  {
    name: "Haiti",
    twoLetterCode: "HT",
    threeLetterCode: "HTI",
    code: "332",
    label: "Haiti",
    phone: "+509",
  },
  {
    name: "Honduras",
    twoLetterCode: "HN",
    threeLetterCode: "HND",
    code: "340",
    label: "Honduras",
    phone: "+504",
  },
  {
    name: "Hungary",
    twoLetterCode: "HU",
    threeLetterCode: "HUN",
    code: "348",
    label: "Hungary",
    phone: "+36",
  },
  {
    name: "Iceland",
    twoLetterCode: "IS",
    threeLetterCode: "ISL",
    code: "352",
    label: "Iceland",
    phone: "+354",
  },
  {
    name: "India",
    twoLetterCode: "IN",
    threeLetterCode: "IND",
    code: "356",
    label: "India",
    phone: "+91",
  },
  {
    name: "Indonesia",
    twoLetterCode: "ID",
    threeLetterCode: "IDN",
    code: "360",
    label: "Indonesia",
    phone: "+62",
  },
  {
    name: "Iran (Islamic Republic of)",
    twoLetterCode: "IR",
    threeLetterCode: "IRN",
    code: "364",
    label: "Iran",
    phone: "+98",
  },
  {
    name: "Iraq",
    twoLetterCode: "IQ",
    threeLetterCode: "IRQ",
    code: "368",
    label: "Iraq",
    phone: "+964",
  },
  {
    name: "Ireland",
    twoLetterCode: "IE",
    threeLetterCode: "IRL",
    code: "372",
    label: "Ireland",
    phone: "+353",
  },
  {
    name: "Israel",
    twoLetterCode: "IL",
    threeLetterCode: "ISR",
    code: "376",
    label: "Israel",
    phone: "+972",
  },
  {
    name: "Italy",
    twoLetterCode: "IT",
    threeLetterCode: "ITA",
    code: "380",
    label: "Italy",
    phone: "+39",
  },
  {
    name: "Jamaica",
    twoLetterCode: "JM",
    threeLetterCode: "JAM",
    code: "388",
    label: "Jamaica",
    phone: "+1-876",
  },
  {
    name: "Japan",
    twoLetterCode: "JP",
    threeLetterCode: "JPN",
    code: "392",
    label: "Japan",
    phone: "+81",
  },
  {
    name: "Jordan",
    twoLetterCode: "JO",
    threeLetterCode: "JOR",
    code: "400",
    label: "Jordan",
    phone: "+962",
  },
  {
    name: "Kazakhstan",
    twoLetterCode: "KZ",
    threeLetterCode: "KAZ",
    code: "398",
    label: "Kazakhstan",
    phone: "+7",
  },
  {
    name: "Kenya",
    twoLetterCode: "KE",
    threeLetterCode: "KEN",
    code: "404",
    label: "Kenya",
    phone: "+254",
  },
  {
    name: "Kiribati",
    twoLetterCode: "KI",
    threeLetterCode: "KIR",
    code: "296",
    label: "Kiribati",
    phone: "+686",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    twoLetterCode: "KP",
    threeLetterCode: "PRK",
    code: "408",
    label: "North Korea",
    phone: "+850",
  },
  {
    name: "Korea (Republic of)",
    twoLetterCode: "KR",
    threeLetterCode: "KOR",
    code: "410",
    label: "South Korea",
    phone: "+82",
  },
  {
    name: "Kuwait",
    twoLetterCode: "KW",
    threeLetterCode: "KWT",
    code: "414",
    label: "Kuwait",
    phone: "+965",
  },
  {
    name: "Kyrgyzstan",
    twoLetterCode: "KG",
    threeLetterCode: "KGZ",
    code: "417",
    label: "Kyrgyzstan",
    phone: "+996",
  },
  {
    name: "Lao People's Democratic Republic",
    twoLetterCode: "LA",
    threeLetterCode: "LAO",
    code: "418",
    label: "Laos",
    phone: "+856",
  },
  {
    name: "Latvia",
    twoLetterCode: "LV",
    threeLetterCode: "LVA",
    code: "428",
    label: "Latvia",
    phone: "+371",
  },
  {
    name: "Lebanon",
    twoLetterCode: "LB",
    threeLetterCode: "LBN",
    code: "422",
    label: "Lebanon",
    phone: "+961",
  },
  {
    name: "Lesotho",
    twoLetterCode: "LS",
    threeLetterCode: "LSO",
    code: "426",
    label: "Lesotho",
    phone: "+266",
  },
  {
    name: "Liberia",
    twoLetterCode: "LR",
    threeLetterCode: "LBR",
    code: "430",
    label: "Liberia",
    phone: "+231",
  },
  {
    name: "Libya",
    twoLetterCode: "LY",
    threeLetterCode: "LBY",
    code: "434",
    label: "Libya",
    phone: "+218",
  },
  {
    name: "Liechtenstein",
    twoLetterCode: "LI",
    threeLetterCode: "LIE",
    code: "438",
    label: "Liechtenstein",
    phone: "+423",
  },
  {
    name: "Lithuania",
    twoLetterCode: "LT",
    threeLetterCode: "LTU",
    code: "440",
    label: "Lithuania",
    phone: "+370",
  },
  {
    name: "Luxembourg",
    twoLetterCode: "LU",
    threeLetterCode: "LUX",
    code: "442",
    label: "Luxembourg",
    phone: "+352",
  },
  {
    name: "Macao",
    twoLetterCode: "MO",
    threeLetterCode: "MAC",
    code: "446",
    label: "Macao",
    phone: "+853",
  },
  {
    name: "Madagascar",
    twoLetterCode: "MG",
    threeLetterCode: "MDG",
    code: "450",
    label: "Madagascar",
    phone: "+261",
  },
  {
    name: "Malawi",
    twoLetterCode: "MW",
    threeLetterCode: "MWI",
    code: "454",
    label: "Malawi",
    phone: "+265",
  },
  {
    name: "Malaysia",
    twoLetterCode: "MY",
    threeLetterCode: "MYS",
    code: "458",
    label: "Malaysia",
    phone: "+60",
  },
  {
    name: "Maldives",
    twoLetterCode: "MV",
    threeLetterCode: "MDV",
    code: "462",
    label: "Maldives",
    phone: "+960",
  },
  {
    name: "Mali",
    twoLetterCode: "ML",
    threeLetterCode: "MLI",
    code: "466",
    label: "Mali",
    phone: "+223",
  },
  {
    name: "Malta",
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    code: "470",
    label: "Malta",
    phone: "+356",
  },
  {
    name: "Marshall Islands",
    twoLetterCode: "MH",
    threeLetterCode: "MHL",
    code: "584",
    label: "Marshall Islands",
    phone: "+692",
  },
  {
    name: "Mauritania",
    twoLetterCode: "MR",
    threeLetterCode: "MRT",
    code: "478",
    label: "Mauritania",
    phone: "+222",
  },
  {
    name: "Mauritius",
    twoLetterCode: "MU",
    threeLetterCode: "MUS",
    code: "480",
    label: "Mauritius",
    phone: "+230",
  },
  {
    name: "Mexico",
    twoLetterCode: "MX",
    threeLetterCode: "MEX",
    code: "484",
    label: "Mexico",
    phone: "+52",
  },
  {
    name: "Micronesia (Federated States of)",
    twoLetterCode: "FM",
    threeLetterCode: "FSM",
    code: "583",
    label: "Micronesia",
    phone: "+691",
  },
  {
    name: "Moldova (Republic of)",
    twoLetterCode: "MD",
    threeLetterCode: "MDA",
    code: "498",
    label: "Moldova",
    phone: "+373",
  },
  {
    name: "Monaco",
    twoLetterCode: "MC",
    threeLetterCode: "MCO",
    code: "492",
    label: "Monaco",
    phone: "+377",
  },
  {
    name: "Mongolia",
    twoLetterCode: "MN",
    threeLetterCode: "MNG",
    code: "496",
    label: "Mongolia",
    phone: "+976",
  },
  {
    name: "Montenegro",
    twoLetterCode: "ME",
    threeLetterCode: "MNE",
    code: "499",
    label: "Montenegro",
    phone: "+382",
  },
  {
    name: "Morocco",
    twoLetterCode: "MA",
    threeLetterCode: "MAR",
    code: "504",
    label: "Morocco",
    phone: "+212",
  },
  {
    name: "Mozambique",
    twoLetterCode: "MZ",
    threeLetterCode: "MOZ",
    code: "508",
    label: "Mozambique",
    phone: "+258",
  },
  {
    name: "Myanmar",
    twoLetterCode: "MM",
    threeLetterCode: "MMR",
    code: "104",
    label: "Myanmar",
    phone: "+95",
  },
  {
    name: "Namibia",
    twoLetterCode: "NA",
    threeLetterCode: "NAM",
    code: "516",
    label: "Namibia",
    phone: "+264",
  },
  {
    name: "Nauru",
    twoLetterCode: "NR",
    threeLetterCode: "NRU",
    code: "520",
    label: "Nauru",
    phone: "+674",
  },
  {
    name: "Nepal",
    twoLetterCode: "NP",
    threeLetterCode: "NPL",
    code: "524",
    label: "Nepal",
    phone: "+977",
  },
  {
    name: "Netherlands",
    twoLetterCode: "NL",
    threeLetterCode: "NLD",
    code: "528",
    label: "Netherlands",
    phone: "+31",
  },
  {
    name: "New Zealand",
    twoLetterCode: "NZ",
    threeLetterCode: "NZL",
    code: "554",
    label: "New Zealand",
    phone: "+64",
  },
  {
    name: "Nicaragua",
    twoLetterCode: "NI",
    threeLetterCode: "NIC",
    code: "558",
    label: "Nicaragua",
    phone: "+505",
  },
  {
    name: "Niger",
    twoLetterCode: "NE",
    threeLetterCode: "NER",
    code: "562",
    label: "Niger",
    phone: "+227",
  },
  {
    name: "Nigeria",
    twoLetterCode: "NG",
    threeLetterCode: "NGA",
    code: "566",
    label: "Nigeria",
    phone: "+234",
  },
  {
    name: "North Macedonia",
    twoLetterCode: "MK",
    threeLetterCode: "MKD",
    code: "807",
    label: "North Macedonia",
    phone: "+389",
  },
  {
    name: "Norway",
    twoLetterCode: "NO",
    threeLetterCode: "NOR",
    code: "578",
    label: "Norway",
    phone: "+47",
  },
  {
    name: "Oman",
    twoLetterCode: "OM",
    threeLetterCode: "OMN",
    code: "512",
    label: "Oman",
    phone: "+968",
  },
  {
    name: "Pakistan",
    twoLetterCode: "PK",
    threeLetterCode: "PAK",
    code: "586",
    label: "Pakistan",
    phone: "+92",
  },
  {
    name: "Palau",
    twoLetterCode: "PW",
    threeLetterCode: "PLW",
    code: "585",
    label: "Palau",
    phone: "+680",
  },
  {
    name: "Panama",
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    code: "591",
    label: "Panama",
    phone: "+507",
  },
  {
    name: "Papua New Guinea",
    twoLetterCode: "PG",
    threeLetterCode: "PNG",
    code: "598",
    label: "Papua New Guinea",
    phone: "+675",
  },
  {
    name: "Paraguay",
    twoLetterCode: "PY",
    threeLetterCode: "PRY",
    code: "600",
    label: "Paraguay",
    phone: "+595",
  },
  {
    name: "Peru",
    twoLetterCode: "PE",
    threeLetterCode: "PER",
    code: "604",
    label: "Peru",
    phone: "+51",
  },
  {
    name: "Philippines",
    twoLetterCode: "PH",
    threeLetterCode: "PHL",
    code: "608",
    label: "Philippines",
    phone: "+63",
  },
  {
    name: "Poland",
    twoLetterCode: "PL",
    threeLetterCode: "POL",
    code: "616",
    label: "Poland",
    phone: "+48",
  },
  {
    name: "Portugal",
    twoLetterCode: "PT",
    threeLetterCode: "PRT",
    code: "620",
    label: "Portugal",
    phone: "+351",
  },
  {
    name: "Qatar",
    twoLetterCode: "QA",
    threeLetterCode: "QAT",
    code: "634",
    label: "Qatar",
    phone: "+974",
  },
  {
    name: "Romania",
    twoLetterCode: "RO",
    threeLetterCode: "ROU",
    code: "642",
    label: "Romania",
    phone: "+40",
  },
  {
    name: "Russian Federation",
    twoLetterCode: "RU",
    threeLetterCode: "RUS",
    code: "643",
    label: "Russia",
    phone: "+7",
  },
  {
    name: "Rwanda",
    twoLetterCode: "RW",
    threeLetterCode: "RWA",
    code: "646",
    label: "Rwanda",
    phone: "+250",
  },
  {
    name: "Saint Kitts and Nevis",
    twoLetterCode: "KN",
    threeLetterCode: "KNA",
    code: "659",
    label: "Saint Kitts and Nevis",
    phone: "+1-869",
  },
  {
    name: "Saint Lucia",
    twoLetterCode: "LC",
    threeLetterCode: "LCA",
    code: "662",
    label: "Saint Lucia",
    phone: "+1-758",
  },
  {
    name: "Saint Vincent and the Grenadines",
    twoLetterCode: "VC",
    threeLetterCode: "VCT",
    code: "670",
    label: "Saint Vincent and the Grenadines",
    phone: "+1-784",
  },
  {
    name: "Samoa",
    twoLetterCode: "WS",
    threeLetterCode: "WSM",
    code: "882",
    label: "Samoa",
    phone: "+685",
  },
  {
    name: "San Marino",
    twoLetterCode: "SM",
    threeLetterCode: "SMR",
    code: "674",
    label: "San Marino",
    phone: "+378",
  },
  {
    name: "Sao Tome and Principe",
    twoLetterCode: "ST",
    threeLetterCode: "STP",
    code: "678",
    label: "Sao Tome and Principe",
    phone: "+239",
  },
  {
    name: "Saudi Arabia",
    twoLetterCode: "SA",
    threeLetterCode: "SAU",
    code: "682",
    label: "Saudi Arabia",
    phone: "+966",
  },
  {
    name: "Senegal",
    twoLetterCode: "SN",
    threeLetterCode: "SEN",
    code: "686",
    label: "Senegal",
    phone: "+221",
  },
  {
    name: "Serbia",
    twoLetterCode: "RS",
    threeLetterCode: "SRB",
    code: "688",
    label: "Serbia",
    phone: "+381",
  },
  {
    name: "Seychelles",
    twoLetterCode: "SC",
    threeLetterCode: "SYC",
    code: "690",
    label: "Seychelles",
    phone: "+248",
  },
  {
    name: "Sierra Leone",
    twoLetterCode: "SL",
    threeLetterCode: "SLE",
    code: "694",
    label: "Sierra Leone",
    phone: "+232",
  },
  {
    name: "Singapore",
    twoLetterCode: "SG",
    threeLetterCode: "SGP",
    code: "702",
    label: "Singapore",
    phone: "+65",
  },
  {
    name: "Slovakia",
    twoLetterCode: "SK",
    threeLetterCode: "SVK",
    code: "703",
    label: "Slovakia",
    phone: "+421",
  },
  {
    name: "Slovenia",
    twoLetterCode: "SI",
    threeLetterCode: "SVN",
    code: "705",
    label: "Slovenia",
    phone: "+386",
  },
  {
    name: "Solomon Islands",
    twoLetterCode: "SB",
    threeLetterCode: "SLB",
    code: "090",
    label: "Solomon Islands",
    phone: "+677",
  },
  {
    name: "Somalia",
    twoLetterCode: "SO",
    threeLetterCode: "SOM",
    code: "706",
    label: "Somalia",
    phone: "+252",
  },
  {
    name: "South Africa",
    twoLetterCode: "ZA",
    threeLetterCode: "ZAF",
    code: "710",
    label: "South Africa",
    phone: "+27",
  },
  {
    name: "South Sudan",
    twoLetterCode: "SS",
    threeLetterCode: "SSD",
    code: "728",
    label: "South Sudan",
    phone: "+211",
  },
  {
    name: "Spain",
    twoLetterCode: "ES",
    threeLetterCode: "ESP",
    code: "724",
    label: "Spain",
    phone: "+34",
  },
  {
    name: "Sri Lanka",
    twoLetterCode: "LK",
    threeLetterCode: "LKA",
    code: "144",
    label: "Sri Lanka",
    phone: "+94",
  },
  {
    name: "Sudan",
    twoLetterCode: "SD",
    threeLetterCode: "SDN",
    code: "729",
    label: "Sudan",
    phone: "+249",
  },
  {
    name: "Suriname",
    twoLetterCode: "SR",
    threeLetterCode: "SUR",
    code: "740",
    label: "Suriname",
    phone: "+597",
  },
  {
    name: "Sweden",
    twoLetterCode: "SE",
    threeLetterCode: "SWE",
    code: "752",
    label: "Sweden",
    phone: "+46",
  },
  {
    name: "Switzerland",
    twoLetterCode: "CH",
    threeLetterCode: "CHE",
    code: "756",
    label: "Switzerland",
    phone: "+41",
  },
  {
    name: "Syrian Arab Republic",
    twoLetterCode: "SY",
    threeLetterCode: "SYR",
    code: "760",
    label: "Syria",
    phone: "+963",
  },
  {
    name: "Taiwan",
    twoLetterCode: "TW",
    threeLetterCode: "TWN",
    code: "158",
    label: "Taiwan",
    phone: "+886",
  },
  {
    name: "Tajikistan",
    twoLetterCode: "TJ",
    threeLetterCode: "TJK",
    code: "762",
    label: "Tajikistan",
    phone: "+992",
  },
  {
    name: "Tanzania (United Republic of)",
    twoLetterCode: "TZ",
    threeLetterCode: "TZA",
    code: "834",
    label: "Tanzania",
    phone: "+255",
  },
  {
    name: "Thailand",
    twoLetterCode: "TH",
    threeLetterCode: "THA",
    code: "764",
    label: "Thailand",
    phone: "+66",
  },
  {
    name: "Timor-Leste",
    twoLetterCode: "TL",
    threeLetterCode: "TLS",
    code: "626",
    label: "Timor-Leste",
    phone: "+670",
  },
  {
    name: "Togo",
    twoLetterCode: "TG",
    threeLetterCode: "TGO",
    code: "768",
    label: "Togo",
    phone: "+228",
  },
  {
    name: "Tokelau",
    twoLetterCode: "TK",
    threeLetterCode: "TKL",
    code: "772",
    label: "Tokelau",
    phone: "+690",
  },
  {
    name: "Tonga",
    twoLetterCode: "TO",
    threeLetterCode: "TON",
    code: "776",
    label: "Tonga",
    phone: "+676",
  },
  {
    name: "Trinidad and Tobago",
    twoLetterCode: "TT",
    threeLetterCode: "TTO",
    code: "780",
    label: "Trinidad and Tobago",
    phone: "+1-868",
  },
  {
    name: "Tunisia",
    twoLetterCode: "TN",
    threeLetterCode: "TUN",
    code: "788",
    label: "Tunisia",
    phone: "+216",
  },
  {
    name: "Turkey",
    twoLetterCode: "TR",
    threeLetterCode: "TUR",
    code: "792",
    label: "Turkey",
    phone: "+90",
  },
  {
    name: "Turkmenistan",
    twoLetterCode: "TM",
    threeLetterCode: "TKM",
    code: "795",
    label: "Turkmenistan",
    phone: "+993",
  },
  {
    name: "Tuvalu",
    twoLetterCode: "TV",
    threeLetterCode: "TUV",
    code: "798",
    label: "Tuvalu",
    phone: "+688",
  },
  {
    name: "Uganda",
    twoLetterCode: "UG",
    threeLetterCode: "UGA",
    code: "800",
    label: "Uganda",
    phone: "+256",
  },
  {
    name: "Ukraine",
    twoLetterCode: "UA",
    threeLetterCode: "UKR",
    code: "804",
    label: "Ukraine",
    phone: "+380",
  },
  {
    name: "United Arab Emirates",
    twoLetterCode: "AE",
    threeLetterCode: "ARE",
    code: "784",
    label: "United Arab Emirates",
    phone: "+971",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    code: "826",
    label: "United Kingdom",
    phone: "+44",
  },
  {
    name: "United States of America",
    twoLetterCode: "US",
    threeLetterCode: "USA",
    code: "840",
    label: "United States",
    phone: "+1",
  },
  {
    name: "Uruguay",
    twoLetterCode: "UY",
    threeLetterCode: "URY",
    code: "858",
    label: "Uruguay",
    phone: "+598",
  },
  {
    name: "Uzbekistan",
    twoLetterCode: "UZ",
    threeLetterCode: "UZB",
    code: "860",
    label: "Uzbekistan",
    phone: "+998",
  },
  {
    name: "Vanuatu",
    twoLetterCode: "VU",
    threeLetterCode: "VUT",
    code: "548",
    label: "Vanuatu",
    phone: "+678",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    twoLetterCode: "VE",
    threeLetterCode: "VEN",
    code: "862",
    label: "Venezuela",
    phone: "+58",
  },
  {
    name: "Viet Nam",
    twoLetterCode: "VN",
    threeLetterCode: "VNM",
    code: "704",
    label: "Vietnam",
    phone: "+84",
  },
  {
    name: "Western Sahara",
    twoLetterCode: "EH",
    threeLetterCode: "ESH",
    code: "732",
    label: "Western Sahara",
    phone: "+212",
  },
  {
    name: "Yemen",
    twoLetterCode: "YE",
    threeLetterCode: "YEM",
    code: "887",
    label: "Yemen",
    phone: "+967",
  },
  {
    name: "Zambia",
    twoLetterCode: "ZM",
    threeLetterCode: "ZMB",
    code: "894",
    label: "Zambia",
    phone: "+260",
  },
  {
    name: "Zimbabwe",
    twoLetterCode: "ZW",
    threeLetterCode: "ZWE",
    code: "716",
    label: "Zimbabwe",
    phone: "+263",
  },
];

export default countries;
